import { useState, useEffect } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const CustomNavbar = () => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const location = useLocation();

	const handleDropdownToggle = (isOpen: boolean) => {
		setDropdownOpen(isOpen);
	};

	const handleDropdownItemClick = () => {
		setDropdownOpen(false);
	};

	useEffect(() => {
		setDropdownOpen(false);
	}, [location]);

	return (
		<>
			<Nav.Link as={Link} to="/map/main">
				Map
			</Nav.Link>
			<Nav.Link as={Link} to="work-orders">
				Work Orders
			</Nav.Link>
			<Nav.Link as={Link} to="task-list">
				Task List
			</Nav.Link>
			<Nav.Link as={Link} to="templates">
				Templates
			</Nav.Link>
			<Nav.Link as={Link} to="forms">
				Forms
			</Nav.Link>
			<NavDropdown title="Logbook" id="nav-dropdown">
				<NavDropdown.Item as={Link} to="/logbook-entry">
					Logbook Entry
				</NavDropdown.Item>
				<NavDropdown.Item as={Link} to="/logbook-report">
					Report
				</NavDropdown.Item>
			</NavDropdown>

			<NavDropdown
				id="user-menu"
				title="Documents"
				className="mx-2"
				show={dropdownOpen}
				onToggle={handleDropdownToggle}
			>
				<NavDropdown.Item
					as={Link}
					to="search-documents"
					onClick={handleDropdownItemClick}
				>
					Search
				</NavDropdown.Item>
				<NavDropdown.Item
					as={Link}
					to="browser-documents"
					onClick={handleDropdownItemClick}
				>
					Browser
				</NavDropdown.Item>
			</NavDropdown>
		</>
	);
};

export { CustomNavbar };
